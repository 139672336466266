<template>
  <div>
    <div class="text-center p-2 my-10 mt-8">
      <img class="mx-auto" src="@/assets/logo.png" />
    </div>
    <div class="my-10">
      <img class="mx-auto w-2/3" src="@/assets/screen-upload-falha.png" />
    </div>
    <div class="mt-10">
      <div class="text-current text-2xl font-normal">
        Ops... Algo deu errado!
      </div>
      <div class="text-gray-500 opacity-80 font-light text-sm px-10 py-3">
        Parece que algo deu errado no envio das suas fotos, clique em voltar e
        tente novamente!
      </div>
    </div>
    <div class="mt-5">
      <button @click="voltar()" class="btn text-center">VOLTAR</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    voltar() {
      this.$router.back();
    },
  },
};
</script>
